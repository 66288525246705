import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface HomeGuardProps {
  children?: ReactNode;
}

const HomeGuard: FC<HomeGuardProps> = () => {
  const { isAuthenticated, connectEmail, user } = useAuth();

  if (isAuthenticated) {
    if (user?.hasAccess) {
      if (user?.isClient) {
        return <Redirect to="/client" />;
      }
      return <Redirect to="/app" />;
    } else {
      return <Redirect to="/no-access" />;
    }
  } else if (!connectEmail) {
    return <Redirect to="/connect" />;
  } else {
    return <Redirect to="/login" />;
  }
};

HomeGuard.propTypes = {
  children: PropTypes.node
};

export default HomeGuard;
