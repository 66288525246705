/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Lab } from 'src/types/lab';
import { Workstation } from 'src/types/workstation';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { laboratory?: Lab; data?: Workstation; itemId?: string };
}

const AddWorkstationModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Workstation>(new Workstation());
  const [errors, setErrors] = useState<{ name: boolean; authorisationNo: boolean }>({
    name: false,
    authorisationNo: false
  });

  useEffect(() => {
    if (rest.visible) {
      if (extra.data) setData({ ...extra.data });
    }
  }, [rest.visible, extra.data]);

  useEffect(() => {
    if (!rest.visible) {
      setData(new Workstation());
    }
  }, [rest.visible]);

  const isValid = (): boolean => {
    const newErrors = { ...errors };
    if (!data.name) {
      newErrors.name = true;
    }
    if (!data.authorisationNo) {
      newErrors.authorisationNo = true;
    }

    if (Object.values(newErrors).some((value: boolean) => value)) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const addAction = async () => {
    if (!isValid()) return;
    const messages = {
      success: 'Punct de lucru adaugat cu succes!',
      failed: 'Problema adaugare punct de lucru'
    };
    try {
      if (!extra.laboratory) {
        throw new Error('Nu s-a gasit un laborator.');
      }
      const payload = {
        ...data,
        laboratory: { id: extra.laboratory.id }
      };
      await api.workstations.create(payload);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const editAction = async () => {
    if (!isValid()) return;
    const messages = {
      success: 'Punct de lucru actualizat cu succes!',
      failed: 'Problema actualizare punct de lucru'
    };
    try {
      await api.workstations.update(extra.itemId, data);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };

  const isEdit = extra?.itemId && extra?.data ? true : false;

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title={isEdit ? 'Editare punct de lucru' : 'Adauga punct de lucru'}
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Denumire"
                name="name"
                fullWidth
                onChange={changeHandler}
                value={data.name}
                variant="outlined"
                InputLabelProps={{ shrink: !!data.name }}
                InputProps={{ inputProps: { min: 0 } }}
                error={errors?.name}
                helperText={errors?.name && 'Denumirea este obligatorie!'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Numar autorizatie"
                name="authorisationNo"
                fullWidth
                onChange={changeHandler}
                value={data.authorisationNo}
                variant="outlined"
                InputLabelProps={{ shrink: !!data.authorisationNo }}
                InputProps={{ inputProps: { min: 0 } }}
                error={errors?.authorisationNo}
                helperText={errors?.authorisationNo && 'Numar autorizatie este obligatoriu!'}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={isEdit ? editAction : addAction}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
          >
            {isEdit ? 'SALVEAZA' : 'ADAUGA'}
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default AddWorkstationModal;
