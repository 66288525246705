const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : '';

export const downloadDocUtil = async (
  id: string,
  config: { title: string; docType: 'CONTRACT' | 'FILE'; token?: string }
) => {
  if (config.docType === 'CONTRACT') config.title += '.pdf';
  const isPDF = config.title.indexOf('pdf') >= 0;

  const headers = {
    'Content-Type': isPDF ? 'application/pdf' : 'blob',
    Authorization: `Bearer ${config.token ?? ''}`
  };

  console.log('Request started');
  return fetch(`${serverUrl}/api/documents/${config.docType.toLowerCase()}/${id}`, {
    method: 'GET',
    headers
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`status ${response.status} ${response.statusText}`);
      }
      return response.blob();
    })
    .then((blob) => {
      console.log('Request finished');
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', config.title);
      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      return true;
    })
    .catch((error) => {
      console.error('Error in downloadFileUtil: ', error);
      return false;
    });
};
