/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Client } from 'src/types/client';
import { User } from 'src/types/user';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { user: User };
}

const AddClientModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState<Client[]>([]);
  const [currentClient, selectClient] = useState<Client>(null);

  const getList = useCallback(async () => {
    try {
      if (isMountedRef.current) {
        const response = await api.clients.list();
        setList(response.result);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getList();
  }, [getList]);

  const addClientAction = async () => {
    const messages = {
      success: 'Adaugare cu succes!',
      failed: 'Problema adaugare'
    };
    try {
      const data = {
        ...extra.user,
        clients: [...extra.user?.clients, currentClient]
      };
      await api.users.update(extra.user?.id, data);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      selectClient(null);
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const getUniqueElements = (arr1: Array<object>, arr2: Array<object>) => {
    const elements = [];
    arr1.forEach((el1: Client) => {
      if (arr2.findIndex((el2: Client) => el2.id === el1.id) === -1) elements.push(el1);
    });
    return elements;
  };

  const listData = getUniqueElements(list, extra.user?.clients);
  const parsedData = listData?.map((element) => ({ key: element.id, value: element.companyName }));

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: { key: string; value: string }
  ) => {
    const client = listData.find((el: Client) => el?.id === value?.key);
    if (client) {
      selectClient(client);
    }
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga client"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Autocomplete
                id="client"
                options={parsedData}
                value={{ key: currentClient?.id, value: currentClient?.companyName } || ''}
                getOptionSelected={(option: { key: string; value: string }, value: { key: string; value: string }) =>
                  value?.key === option.key || !value?.key
                }
                getOptionLabel={(option: { key: string; value: string }) => option.value || ''}
                onChange={changeHandler}
                autoHighlight
                blurOnSelect
                renderInput={(params) => <TextField {...params} fullWidth label="Client" variant="outlined" />}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={addClientAction}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!currentClient}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default AddClientModal;
