import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import Form from 'src/components/Form';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Probe, ProbeRegistryBody } from 'src/types/probe';
import { User } from 'src/types/user';
import * as Yup from 'yup';

import { Card, CardContent, CardHeader, Divider, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { probe: Probe; user: User; edit?: boolean };
}

interface AddRegistryForm {
  registryDate: string;
  registryManager: string;
  registryEvaluation: string;
}

const ProbeAddRegistryModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [probes, setProbes] = useState<Probe[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadDependences = useCallback(async () => {
    setLoading(true);
    try {
      const probe = await api.probes.get(extra.probe?.id);
      const probesPg = (await api.probes.listPg({ criteria: { pvpId: probe.pvp?.id } })).result;
      setProbes(probesPg);
    } catch (e) {
      enqueueSnackbar('Problema incarcare probe', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [extra, enqueueSnackbar]);

  useEffect(() => {
    if (rest.visible) setTimeout(loadDependences, 100);
  }, [rest.visible, extra, loadDependences]);

  const addToRegistry = async (formData: AddRegistryForm): Promise<boolean> => {
    const probeRegistryBody: ProbeRegistryBody = {
      data: {
        registryDate: new Date(formData.registryDate),
        registryManager: formData.registryManager,
        registryEvaluation: formData.registryEvaluation
      },
      ids: probes.map((p) => p.id)
    };
    let response: { success: boolean };
    if (extra?.edit) {
      response = await api.probes.editRegistry(probeRegistryBody);
    } else {
      response = await api.probes.addToRegistry(probeRegistryBody);
    }
    if (!response?.success) {
      // enqueueSnackbar(`Problema receptie probe.`, { variant: 'error' });
      throw new Error('No probes changed');
    }
    close()();
    return true;
  };

  const close = (withoutNotify?: boolean) => () => {
    rest.cancel();
    setTimeout(() => {
      if (!withoutNotify) rest.setNotify(new Date().getTime());
      setProbes([]);
    }, 500);
  };

  const probeString = probes.map((p) => p.pno).join(', ');
  const clientCompanyName = probes?.[0]?.clientCompanyName;
  const pvpString = extra?.probe?.pvp?.pno;
  const formDisabled = !probes.length;

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title={extra.edit ? 'Editare receptie probe' : 'Adauga receptie probe'}
          action={
            <IconButton aria-label="close" onClick={close(true)}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          {extra?.edit && (
            <Typography style={{ color: 'red', marginBottom: 20 }}>
              Atentie: se vor anula probele existente si se vor crea noi probe.
            </Typography>
          )}
          <Typography>
            <b>Client: </b>
            {clientCompanyName || 'Se incarca...'}
          </Typography>
          <Typography>
            <b>Proces verbal de prelevare:</b> {pvpString}
          </Typography>
          <Typography>
            <b>Probe:</b> {probeString || 'Se incarca...'}
          </Typography>
          <div style={{ marginTop: 30 }}>
            <Form
              fields={[
                {
                  label: 'Data receptiei',
                  name: 'registryDate',
                  validation: Yup.date().required('Numele este obligatoriu'),
                  type: 'date',
                  initialValue: new Date().toISOString()
                },
                {
                  label: 'Nume executant',
                  name: 'registryManager',
                  validation: Yup.string().max(255).required('Numele este obligatoriu'),
                  initialValue: `${extra.user?.firstName} ${extra.user?.lastName}`
                },
                {
                  label: 'Starea probelor',
                  name: 'registryEvaluation',
                  validation: Yup.string().max(255).required('Numele este obligatoriu'),
                  initialValue: 'Buna'
                }
              ]}
              messages={{
                success: 'Receptie probe efectuata cu success',
                fail: 'Problema adaugare receptie probe'
              }}
              action={addToRegistry}
              submitLabel={extra?.edit ? 'Editeaza' : 'Adauga'}
              cardTitle="Recetie probe"
              onlyFields
              disabled={formDisabled}
              loading={loading}
            />
          </div>
        </CardContent>
        {/* <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={addToRegistry}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={formDisabled}
            loading={loading}
          >
            Adauga
          </Button>
        </div> */}
      </Card>
    </DialogAdvanced>
  );
};

export default ProbeAddRegistryModal;
