const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : '';

export enum FILE_TYPE {
  PDF = 'pdf',
  XLSX = 'xlsx'
}

const downloadContractUtil = async (
  url: string,
  config: { title: string; version?: number | string; type?: FILE_TYPE; token?: string }
) => {
  const fileType = config.type ?? FILE_TYPE.PDF;
  let filename = `${config.title}.${fileType}`;
  if (config.version) {
    filename = `${config.title}-${config.version}.${fileType}`;
  }
  const headers = {
    'Content-Type': FILE_TYPE.PDF ? 'application/pdf' : 'blob',
    Authorization: `Bearer ${config.token ?? ''}`
  };

  console.log('Request started');
  return fetch(serverUrl + url, {
    method: 'GET',
    headers
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`status ${response.status} ${response.statusText}`);
      }
      return response.blob();
    })
    .then((blob) => {
      console.log('Request finished');
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      return true;
    })
    .catch((error) => {
      console.error('Error in downloadFileUtil: ', error);
    });
};

export default downloadContractUtil;
