import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Dialog from 'src/components/Dialog';
import { ATTACHMENT_TYPE, UploadedFile } from 'src/types/file';
import bytesToSize from 'src/utils/bytesToSize';
import { downloadDocUtil } from 'src/utils/downloadDocument';

import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import type { FC } from 'react';
import type { Theme } from 'src/theme';
interface FilesDropzoneProps {
  values: UploadedFile[];
  attachments: UploadedFile[];
  className?: string;
  multiple?: boolean;
  onDrop: (files: any) => void;
  onDelete?: (index: number, fileId: string) => void;
  filterType?: ATTACHMENT_TYPE;
  noAdd?: boolean;
  noEmail?: boolean;
  token: string;
  customText?: string;
  onEmailSent: (value: any) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    '&:hover': {
      cursor: 'default'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 60,
    margin: theme.spacing(1)
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 200
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  redirectText: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const labels = {
  single: { title: 'Alegeti fisierul', description: 'Incarcati fisierul prin drag-and-drop' },
  multi: { title: 'Alegeti fisierele', description: 'Incarcati fisierele prin drag-and-drop' }
};

const FilesDropzone: FC<FilesDropzoneProps> = ({
  onDrop,
  onDelete,
  multiple,
  values,
  attachments,
  className,
  token,
  ...rest
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState<{ visible: boolean; fileId: string; index: number }>({
    visible: false,
    fileId: null,
    index: null
  });
  let filteredFiles = [...values];
  if (rest.filterType) filteredFiles = filteredFiles.filter((v) => v.attachmentType === rest.filterType);
  const files = [...filteredFiles, ...attachments];
  const usedLabels = multiple ? labels.multi : labels.single;
  if (rest.customText) usedLabels.title = rest.customText;
  const disabled = !multiple && !!files.length;

  const handleDelete = (index: number, fileId: string) => () => {
    if (!fileId) {
      onDelete(index, null);
    } else {
      setModal({ visible: true, fileId, index });
    }
  };

  const cancelDelete = () => {
    setModal({ visible: false, fileId: null, index: null });
  };

  const approveDelete = () => {
    onDelete(modal.index, modal.fileId);
    setModal({ visible: false, fileId: null, index: null });
  };

  const onClick = (index: number) => () => {
    // uncomment for local development
    // const win = window.open(`http://localhost:8000/api/files/${files[index].id}`, '_blank');
    // const win = window.open(`${serverUrl}${API_SUFFIX}/files/${files[index].id}`, '_blank');
    // win.focus();
    const file = files[index];
    downloadDocUtil(file.id, {
      title: file.originalName,
      docType: 'FILE',
      token
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple ?? false,
    disabled
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {!rest.noAdd && (
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive,
            [classes.disabled]: disabled
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div>
            <img alt="Select file" className={classes.image} src="/static/images/undraw_add_file2_gvbb.svg" />
          </div>
          <div>
            <Typography variant="h4">{usedLabels.title}</Typography>
            <Box mt={2}>
              <Typography color="textPrimary">
                {usedLabels.description} sau <Link underline="always">click</Link>!
              </Typography>
            </Box>
          </div>
        </div>
      )}
      {!files.length && rest.noAdd && (
        <>
          <Typography>Nu sunt documente disponibile.</Typography>
        </>
      )}
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={clsx({
                      [classes.redirectText]: !!file.uri
                    })}
                    primary={file.name || file.originalName}
                    primaryTypographyProps={{ variant: 'h4' }}
                    secondary={`${file.created ? new Date(file.created).toLocaleString('ro-RO') : ''} (${
                      file.size ? bytesToSize(file.size) : ''
                    })`}
                    onClick={file.id && onClick(i)}
                  />
                  {rest.onEmailSent && !rest.noEmail && (
                    <Tooltip title="Trimite email">
                      <IconButton edge="end" onClick={() => rest.onEmailSent(file)}>
                        <SvgIcon color="primary">
                          <EmailIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {onDelete && !rest.noAdd && (
                    <Tooltip title="Stergere">
                      <IconButton edge="end" onClick={handleDelete(i, file.id)}>
                        <SvgIcon color="error">
                          <DeleteIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <Dialog
            visible={modal.visible}
            title="STERGERE"
            question="Doriti stergerea fisierului?"
            actionTitle="Sterge"
            cancel={cancelDelete}
            action={approveDelete}
          />
        </>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
