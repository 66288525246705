// eslint-disable-next-line import/prefer-default-export
export const getQuery = (query: any, path: string) => {
  let newPath = `${path}?`;
  Object.keys(query).forEach((key: string) => {
    let value = query[key];
    if (value !== undefined) {
      if (typeof query[key] === 'object') {
        value = JSON.stringify(query[key]);
      }
      newPath += `${key}=${value}&`;
    }
  });
  return newPath.substring(0, newPath.length - 1);
};
