import { AxiosResponse } from 'axios';
import { generatePath } from 'react-router';
import { Category } from 'src/types/category';
import { Client } from 'src/types/client';
import { CompanyData } from 'src/types/companyData';
import { Contract, DocumentAttributes, ProjectContract } from 'src/types/contract';
import { Document } from 'src/types/doc';
import { Email } from 'src/types/email';
import { UploadedFile } from 'src/types/file';
import { Lab } from 'src/types/lab';
import { LabCategory } from 'src/types/labCategory';
import { Order } from 'src/types/order';
import { PaginatedQuery } from 'src/types/paginatedQuery';
import { Probe, ProbeRegistryBody } from 'src/types/probe';
import { LabProduct, Product, ProjectProduct } from 'src/types/product';
import { Project } from 'src/types/project';
import { Report } from 'src/types/report';
import { Role } from 'src/types/role';
import { Site } from 'src/types/site';
import { User } from 'src/types/user';
import { Workstation } from 'src/types/workstation';
import axios from 'src/utils/axios';
import { getQuery } from 'src/utils/query';

enum METHODS {
  GET,
  POST,
  PATCH,
  DELETE
}

export const API_SUFFIX = '/api';
let pdfUrl = '/api/contracts/pdf';
if (window.location.hostname === 'localhost') pdfUrl = 'http://localhost:8000/api/contracts/pdf';
export const API_PDF_DOWNLOAD = pdfUrl;

async function call<T>(method: METHODS, endpoint: string, suffix?: string, body?: unknown) {
  const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : '';
  const url = serverUrl + endpoint + (suffix ? `/${suffix}` : '');
  console.log(`[REQUEST][${METHODS[method]}] ${url} `);
  let response: AxiosResponse<T>;
  switch (method) {
    case METHODS.GET:
      response = await axios.get<T>(url);
      break;
    case METHODS.PATCH:
      response = await axios.patch<T>(url, body);
      break;
    case METHODS.POST:
      response = await axios.post<T>(url, body);
      break;
    case METHODS.DELETE:
      response = await axios.delete<T>(url);
      break;
  }
  return response.data;
}

function getAction<Model>(endpoint: string) {
  return (id?: string) => call<Model>(METHODS.GET, endpoint, id);
}

function listAction<Model>(endpoint: string) {
  return (id?: string) => call<{ result: Model[] }>(METHODS.GET, endpoint, id);
}

function listPaginatedAction<Model>(endpoint: string) {
  return (query?: PaginatedQuery) => {
    let parsedEndpoint = endpoint;
    if (query) {
      parsedEndpoint += '?';
      Object.keys(query).forEach((key: string) => {
        let value = query[key];
        if (value !== undefined) {
          if (typeof query[key] === 'object') {
            value = JSON.stringify(query[key]);
          }
          parsedEndpoint += `${key}=${value}&`;
        }
      });
      parsedEndpoint = parsedEndpoint.substr(0, parsedEndpoint.length - 1);
    }
    return call<{ result: Model[]; total: number; skip: number; limit: number }>(METHODS.GET, parsedEndpoint);
  };
}

function updateAction<Model>(endpoint: string) {
  return (id?: string, entity?: Partial<Model>) => call<Model>(METHODS.PATCH, endpoint, id, entity);
}

function createAction<Model, ResultModel = Model>(endpoint: string) {
  return (entity?: Model, id?: string) => call<ResultModel>(METHODS.POST, endpoint, id, entity);
}

function customCreate<Model>(endpoint: string) {
  return (
    params: { [paramName: string]: string | number | boolean },
    entity: Model,
    query?: { [key: string]: string | number | boolean | object }
  ) => {
    let path = generatePath(endpoint, params);
    if (query) {
      path = getQuery(query, path);
    }
    return call<Model>(METHODS.POST, path, null, entity);
  };
}

function deleteAction(endpoint: string) {
  return (id?: string) => call<{ success: boolean }>(METHODS.DELETE, endpoint, id);
}

function crudGenerator<Model>(endpoint) {
  return {
    get: getAction<Model>(endpoint),
    list: listAction<Model>(endpoint),
    listPg: listPaginatedAction<Model>(endpoint),
    update: updateAction<Model>(endpoint),
    create: createAction<Model>(endpoint),
    delete: deleteAction(endpoint)
  };
}

const ENDPOINTS: { [key: string]: { [key: string]: string } } = {
  users: {
    base: `${API_SUFFIX}/users`,
    list: `${API_SUFFIX}/users/list`
  },
  labs: {
    base: `${API_SUFFIX}/laboratories`,
    contract: `${API_SUFFIX}/laboratories/doc/contract`,
    offer: `${API_SUFFIX}/laboratories/doc/offer`
  },
  labProducts: {
    base: `${API_SUFFIX}/laboratory-products`
  },
  categories: {
    base: `${API_SUFFIX}/categories`
  },
  roles: {
    base: `${API_SUFFIX}/roles`
  },
  products: {
    base: `${API_SUFFIX}/products`
  },
  clients: {
    base: `${API_SUFFIX}/clients`
  },
  sites: { base: `${API_SUFFIX}/sites` },
  files: {
    base: `${API_SUFFIX}/files`
  },
  contracts: {
    base: `${API_SUFFIX}/contracts`,
    attachment: `${API_SUFFIX}/contracts/attachment`,
    pdf: `${API_SUFFIX}/contracts/pdf`,
    check: `${API_SUFFIX}/contracts/check`
  },
  projects: {
    base: `${API_SUFFIX}/projects`,
    contract: `${API_SUFFIX}/projects/doc/contract`,
    offer: `${API_SUFFIX}/projects/doc/offer`,
    files: `${API_SUFFIX}/projects/files`
  },
  clientProjects: {
    base: `${API_SUFFIX}/client-projects`,
    docs: `${API_SUFFIX}/client-projects/docs`
  },
  projectProducts: {
    base: `${API_SUFFIX}/project-products`,
    listForProject: `${API_SUFFIX}/project-products/project`
  },
  projectContracts: {
    base: `${API_SUFFIX}/project-contracts`,
    listContracts: `${API_SUFFIX}/project-contracts/list/contract`,
    listOffers: `${API_SUFFIX}/project-contracts/list/offer`,
    listOrderTemplates: `${API_SUFFIX}/project-contracts/list/order_template`,
    listReports: `${API_SUFFIX}/project-contracts/list/report`,
    listReportTemplates: `${API_SUFFIX}/project-contracts/list/report_template`,
    listPVPs: `${API_SUFFIX}/project-contracts/list/pvp`,
    listPVPTemplates: `${API_SUFFIX}/project-contracts/list/pvp_template`,
    generateContract: `${API_SUFFIX}/project-contracts/generate/contract`,
    generateOffer: `${API_SUFFIX}/project-contracts/generate/offer`,
    generateOrderTemplate: `${API_SUFFIX}/project-contracts/generate/order_template`,
    generateReportTemplate: `${API_SUFFIX}/project-contracts/generate/report_template`,
    generatePVPTemplate: `${API_SUFFIX}/project-contracts/generate/pvp_template`,
    generateReportWA: `${API_SUFFIX}/project-contracts/generate/report`,
    generatePVPWA: `${API_SUFFIX}/project-contracts/generate/pvp`,
    revoke: `${API_SUFFIX}/project-contracts/revoke`
  },
  emails: {
    base: `${API_SUFFIX}/emails`
  },
  labCategories: {
    base: `${API_SUFFIX}/laboratory-categories`,
    listByLab: `${API_SUFFIX}/laboratory-categories/laboratory`
  },
  orders: {
    base: `${API_SUFFIX}/orders`,
    ordersByProject: `${API_SUFFIX}/orders/project`
  },
  reports: {
    base: `${API_SUFFIX}/reports`,
    generate: `${API_SUFFIX}/reports/generate`
  },
  probes: {
    base: `${API_SUFFIX}/probes`,
    addToRegistry: `${API_SUFFIX}/probes/add-registry`,
    editRegistry: `${API_SUFFIX}/probes/edit-registry`
  },
  workstations: {
    base: `${API_SUFFIX}/workstations`
  },
  utils: {
    getCompany: `${API_SUFFIX}/utils/company`
  }
};

export default {
  users: crudGenerator<User>(ENDPOINTS.users.base),
  labs: {
    ...crudGenerator<Lab>(ENDPOINTS.labs.base),
    contract: getAction<Contract>(ENDPOINTS.labs.contract),
    offer: getAction<Contract>(ENDPOINTS.labs.offer)
  },
  categories: crudGenerator<Category>(ENDPOINTS.categories.base),
  roles: crudGenerator<Role>(ENDPOINTS.roles.base),
  products: crudGenerator<Product>(ENDPOINTS.products.base),
  labProducts: crudGenerator<LabProduct>(ENDPOINTS.labProducts.base),
  clients: crudGenerator<Client>(ENDPOINTS.clients.base),
  sites: crudGenerator<Site>(ENDPOINTS.sites.base),
  files: crudGenerator<UploadedFile>(ENDPOINTS.files.base),
  contracts: {
    ...crudGenerator<Contract>(ENDPOINTS.contracts.base),
    attachment: customCreate<FormData>(ENDPOINTS.contracts.attachment),
    pdf: getAction<UploadedFile>(ENDPOINTS.contracts.pdf),
    check: getAction<{ title: string; id: string }>(ENDPOINTS.contracts.check)
  },
  projects: {
    ...crudGenerator<Project>(ENDPOINTS.projects.base),
    contract: getAction<Contract>(ENDPOINTS.projects.contract),
    offer: getAction<Contract>(ENDPOINTS.projects.offer),
    files: createAction<Project>(ENDPOINTS.projects.files)
  },
  clientProjects: {
    ...crudGenerator<Project>(ENDPOINTS.clientProjects.base),
    docs: listAction<Document>(ENDPOINTS.clientProjects.docs),
    uploadDocs: createAction<Document>(ENDPOINTS.clientProjects.docs)
  },
  projectProducts: {
    ...crudGenerator<ProjectProduct>(ENDPOINTS.projectProducts.base),
    listForProject: listAction<ProjectProduct>(ENDPOINTS.projectProducts.listForProject)
  },
  projectContracts: {
    ...crudGenerator<ProjectContract>(ENDPOINTS.projectContracts.base),
    listContracts: listAction<ProjectContract>(ENDPOINTS.projectContracts.listContracts),
    listOffers: listAction<ProjectContract>(ENDPOINTS.projectContracts.listOffers),
    listOrderTemplates: listAction<ProjectContract>(ENDPOINTS.projectContracts.listOrderTemplates),
    listReports: listAction<ProjectContract>(ENDPOINTS.projectContracts.listReports),
    listReportTemplates: listAction<ProjectContract>(ENDPOINTS.projectContracts.listReportTemplates),
    listPVPs: listAction<ProjectContract>(ENDPOINTS.projectContracts.listPVPs),
    listPVPTemplates: listAction<ProjectContract>(ENDPOINTS.projectContracts.listPVPTemplates),
    generateContract: getAction<ProjectContract>(ENDPOINTS.projectContracts.generateContract),
    generateOffer: getAction<ProjectContract>(ENDPOINTS.projectContracts.generateOffer),
    generateOrderTemplate: getAction<ProjectContract>(ENDPOINTS.projectContracts.generateOrderTemplate),
    generateReportTemplate: getAction<ProjectContract>(ENDPOINTS.projectContracts.generateReportTemplate),
    generatePVPTemplate: getAction<ProjectContract>(ENDPOINTS.projectContracts.generatePVPTemplate),
    generateReportWA: createAction<DocumentAttributes>(ENDPOINTS.projectContracts.generateReportWA),
    generatePVPWA: createAction<DocumentAttributes>(ENDPOINTS.projectContracts.generatePVPWA),
    revoke: updateAction<DocumentAttributes>(ENDPOINTS.projectContracts.revoke)
  },
  emails: {
    ...crudGenerator<Email>(ENDPOINTS.emails.base)
  },
  labCategories: {
    ...crudGenerator<LabCategory>(ENDPOINTS.labCategories.base),
    listByLab: listAction<LabCategory>(ENDPOINTS.labCategories.listByLab)
  },
  orders: {
    ...crudGenerator<Order>(ENDPOINTS.orders.base),
    ordersByProject: listAction<Order>(ENDPOINTS.orders.ordersByProject)
  },
  reports: {
    ...crudGenerator<Report>(ENDPOINTS.reports.base),
    generate: getAction<Report>(ENDPOINTS.reports.generate)
  },
  probes: {
    ...crudGenerator<Probe>(ENDPOINTS.probes.base),
    addToRegistry: createAction<ProbeRegistryBody, { success: boolean }>(ENDPOINTS.probes.addToRegistry),
    editRegistry: createAction<ProbeRegistryBody, { success: boolean }>(ENDPOINTS.probes.editRegistry)
  },
  workstations: crudGenerator<Workstation>(ENDPOINTS.workstations.base),
  utils: {
    getCompany: getAction<CompanyData>(ENDPOINTS.utils.getCompany)
  }
};
