import React, { FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Category } from 'src/types/category';
import { Product, ProjectProduct } from 'src/types/product';
import ProductEditForm from 'src/views/products/ProductEditView/ProductEditForm';

import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const allLabels = {
  pageTitle: 'Adauga serviciu',
  messages: {
    success: 'Serviciu adaugat',
    fail: 'Problema adaugare date serviciu'
  },
  cardTitle: 'Adauga serviciu',
  submitLabel: 'Adauga'
};

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { projectId: string };
}

const ProducModal: FC<Props> = ({ extra, ...rest }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  const saveHandler = async (entity: Product) => {
    if (extra?.projectId) {
      const projectProduct = new ProjectProduct(entity, extra.projectId);
      const savedProjectProduct = await api.projectProducts.create(projectProduct);
      rest.setNotify(savedProjectProduct);
      rest.cancel();
      return savedProjectProduct;
    }
  };

  const loadDependences = useCallback(async () => {
    setCategories((await api.categories.list()).result);
  }, []);

  useEffect(() => {
    loadDependences();
  }, [loadDependences]);

  return (
    <DialogAdvanced {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProductEditForm
            entity={new Product()}
            loading={false}
            action={saveHandler}
            labels={allLabels}
            extra={{ categories }}
            cardHeaderAction={
              <IconButton aria-label="close" onClick={rest.cancel}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
    </DialogAdvanced>
  );
};

export default ProducModal;
