import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user?.hasAccess) {
      if (user?.isClient) {
        return <Redirect to="/client" />;
      }
      return <Redirect to="/app" />;
    } else {
      return <Redirect to="/no-access" />;
    }
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
