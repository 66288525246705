import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import DocumentForm from 'src/components/DocumentForm';
import useGlobalModal, { MODALS } from 'src/hooks/useGlobalModal';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Category } from 'src/types/category';
import { DocumentAttributes, ProjectContract, TemplateAttribute } from 'src/types/contract';
import { LabCategory } from 'src/types/labCategory';
import { Probe, PROBE_STATUS } from 'src/types/probe';
import { ProjectProduct } from 'src/types/product';
import { User } from 'src/types/user';
import { Workstation } from 'src/types/workstation';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Skeleton from '@material-ui/lab/Skeleton';

const REPORT_MANDATORY_FIELDS = [{ key: 'data_document', type: 'date' }];

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  noProbe: {
    fontSize: '1rem',
    marginLeft: 14
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra: { projectId: string; user: User; pvpId?: string; categoryId?: string };
}

const ProjectReportCreateModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useGlobalModal();
  const [deps, setDeps] = useState<{
    categories: Category[];
    labCategories: LabCategory[];
    pvps: ProjectContract[];
    projectProducts: ProjectProduct[];
    workstations: Workstation[];
  }>({
    categories: [],
    labCategories: [],
    pvps: [],
    projectProducts: [],
    workstations: []
  });
  const [attributes, setAttributes] = useState<DocumentAttributes>({});
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  // const [probesError, setProbesError] = useState<string>(null);
  const [category, setCategory] = useState<Category>(null);
  const [workstation, setWorkstation] = useState<Workstation>(extra?.user?.workstation);
  const [template, setTemplate] = useState<ProjectContract>(null);
  const [pvp, setPvp] = useState<ProjectContract>(null);
  // const [products, setProducts] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [probe, setProbe] = useState<Probe[]>([]);
  const [probeListUnreceived, setProbeListUnreceived] = useState<Probe[]>([]);
  const [probeListUntested, setProbeListUntested] = useState<Probe[]>([]);
  const [probeListReported, setProbeListReported] = useState<Probe[]>([]);
  const [probeList, setProbeList] = useState<Probe[]>([]);
  const [selectedProbes, setSelectedProbes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDependences, setLoadingDependences] = useState<boolean>(false);

  const isValid = (): boolean => {
    const newErrors = {};
    REPORT_MANDATORY_FIELDS.forEach((el: TemplateAttribute) => {
      if (!attributes[el.key]) {
        newErrors[el.key] = true;
      }
    });
    if (!selectedProbes.length) {
      // eslint-disable-next-line dot-notation
      newErrors['probe'] = true;
    }
    if (!workstation) {
      // eslint-disable-next-line dot-notation
      newErrors['workstation'] = true;
    }
    setError(newErrors);
    return !Object.keys(newErrors)?.length;
  };

  const getSelectedProbesIds = (): string => {
    let ids = '';
    selectedProbes.forEach((pno: string) => {
      const proba: Probe | undefined = probe.find((el: Probe) => el.pno === pno);
      if (proba) {
        if (ids) ids += ',';
        ids += proba.id;
      }
    });
    return ids;
  };

  const saveHandler = async () => {
    if (!isValid()) return;
    try {
      setLoading(true);
      attributes['extra.projectId'] = extra?.projectId;
      attributes['extra.categoryId'] = category.id;
      attributes['extra.workstationId'] = workstation.id;
      attributes['extra.probeIds'] = getSelectedProbesIds();
      if (pvp) attributes['extra.pvpId'] = pvp.id;
      attributes['report.method'] = selectedProducts.join(', ');
      const pc = await api.projectContracts.generateReportWA(attributes, template.id);
      setLoading(false);
      enqueueSnackbar('Raport creat cu success', { variant: 'success' });
      rest.setNotify(pc.id);
      rest.cancel();
      openModal(MODALS.TEXT_EDITOR, {
        projectContractId: pc.id,
        canEdit: true,
        viewMode: true,
        title: 'Raport'
      });
    } catch (e) {
      setLoading(false);
      let errorMessage = e?.message;
      try {
        errorMessage = JSON.parse(e?.message);
      } catch (er) {
        console.log('Error', er);
      }

      if (errorMessage?.message?.includes('duplicate key value violates unique constraint')) {
        enqueueSnackbar('Nu se poate adauga inainte de primul PVP', { variant: 'error' });
      } else {
        enqueueSnackbar(`Problema adaugare raport: ${e?.message}`, { variant: 'error' });
      }
    }
  };

  const loadDependences = useCallback(async () => {
    try {
      setLoadingDependences(true);
      const project = await api.projects.get(extra.projectId);
      const lab = await api.labs.get(project.laboratory?.id);
      const { workstations } = lab;
      const categories = (await api.categories.list()).result?.filter((cat: Category) => cat.forPvp);
      const labCategories = (await api.labCategories.listByLab(`${project.laboratory?.id}?type=report_template`))
        .result;
      const pvps = (await api.projectContracts.listPVPs(extra?.projectId)).result;
      const projectProducts = (await api.projectProducts.listForProject(extra?.projectId)).result;
      setDeps({
        categories,
        labCategories,
        pvps,
        projectProducts,
        workstations
      });
      setWorkstation(extra.user.workstation);
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setLoadingDependences(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra]);

  useEffect(() => {
    if (!rest.visible) {
      setAttributes({});
      setError({});
      // setProbesError(null);
      setCategory(null);
      setWorkstation(null);
      setTemplate(null);
      setPvp(null);
      // setProducts([]);
      setSelectedProducts([]);
      setProbe([]);
      setProbeList([]);
      setProbeListReported([]);
      setProbeListUntested([]);
      setProbeListUnreceived([]);
      setSelectedProbes([]);
    } else {
      loadDependences();
    }
  }, [rest.visible, loadDependences]);

  const changeCategory = (catId: string) => {
    const cat = deps?.categories.find((p) => p.id === catId) || null;
    const labCat = (cat && deps?.labCategories.find((lc) => lc.category.id === cat.id)) || null;
    if (labCat?.projectContract) {
      setTemplate(labCat?.projectContract);
    } else setTemplate(null);
    // if (cat && deps?.projectProducts?.length) {
    //   const prods = deps?.projectProducts.filter((pp) => pp.category?.id === cat.id).map((pp) => pp.name);
    //   setProducts(prods);
    // }
    setCategory(cat);
  };

  const handleChangeCategory = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeCategory(event.target.value);
    setPvp(null);
  };

  const changePvp = async (pvpId: string) => {
    // setProbesError(null);
    const pv = deps?.pvps.find((p) => p.id === pvpId) || null;
    setPvp(pv);
    const probes = (await api.probes.listPg({ criteria: { pvpId: pv?.id } })).result;
    setProbe(probes);
    setError({ ...error, probe: false });
    const probesReported: Probe[] = [];
    const probesUntested: Probe[] = [];
    const probesUnreceived: Probe[] = [];
    const probesNormal: Probe[] = [];
    probes.forEach((p) => {
      if (p.status === PROBE_STATUS.NEW) {
        probesUnreceived.push(p);
      } else if (p.status === PROBE_STATUS.FINAL) {
        probesReported.push(p);
      } else if (p.status === PROBE_STATUS.READY) {
        probesNormal.push(p);
      } else {
        probesUntested.push(p);
      }
    });
    setProbeListReported(probesReported);
    setProbeListUntested(probesUntested);
    setProbeListUnreceived(probesUnreceived);
    setProbeList(probesNormal);
  };

  useEffect(() => {
    if (
      rest.visible &&
      !category &&
      !pvp &&
      deps.categories.length &&
      deps.labCategories.length &&
      deps.pvps.length &&
      !loadingDependences
    ) {
      if (extra.categoryId) {
        if (extra?.categoryId) {
          changeCategory(extra.categoryId);
        }
        if (extra?.pvpId) {
          changePvp(extra.pvpId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.visible, deps, loadingDependences]);

  const handleChangePvp = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLoading(true);
    await changePvp(event.target.value);
    setLoading(false);
  };

  const resetError = (data: DocumentAttributes) => {
    const newError = { ...error };
    Object.keys(data).forEach((key: string) => {
      if (newError[key]) newError[key] = false;
    });
    setError(newError);
  };

  const handleChangeData = (data: DocumentAttributes) => {
    resetError(data);
    setAttributes(data);
  };

  // const handleChangeProducts = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   const value = event.target.value as unknown;
  //   setSelectedProducts(value as string[]);
  // };

  const handleChangeProbes = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value as unknown;
    let newSelectedProbes = [...selectedProbes];
    if (newSelectedProbes.find((sp) => sp === value)) {
      newSelectedProbes = selectedProbes.filter((sp) => sp !== value);
    } else {
      newSelectedProbes.push(value as string);
    }
    setSelectedProbes(newSelectedProbes);
    setError({ ...error, probe: false });
  };

  const handleChangeWorkstation = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const work = deps?.workstations.find((w) => w.id === event.target.value) || null;
    setWorkstation(work);
    if (error?.workstation) setError({ ...error, workstation: false });
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga raport"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <TextField
                  fullWidth
                  label="Punct de lucru"
                  onChange={handleChangeWorkstation}
                  value={workstation?.id}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={error?.workstation}
                  disabled
                  InputLabelProps={{ shrink: !!workstation?.id }}
                  helperText={error?.workstation && 'Punctul de lucru este obligatoriu!'}
                >
                  <option key="" value="" />
                  {deps?.workstations &&
                    deps.workstations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              {loadingDependences ? (
                <Skeleton height={56} animation="wave" />
              ) : (
                <>
                  <TextField
                    fullWidth
                    label="Categorie"
                    onChange={handleChangeCategory}
                    value={category?.id}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{ shrink: !!category?.id }}
                  >
                    <option key="" value="" />
                    {deps?.categories &&
                      deps.categories.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                  {!!category && !template && (
                    <Box>
                      <FormHelperText error>Aceasta categorie nu are un template definit!</FormHelperText>
                    </Box>
                  )}
                </>
              )}
            </Grid>
            {template && !loadingDependences && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Selecteaza PVP"
                    onChange={handleChangePvp}
                    value={pvp?.id}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{ shrink: !!pvp?.id }}
                  >
                    <option key="" value="" />
                    {deps?.pvps &&
                      deps.pvps.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.contract.title}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                {!!pvp && (
                  <Grid item xs={12}>
                    {!!probeList.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe eligibile</FormHelperText>
                        {probeList.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                value={p.pno}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListUnreceived.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe ne-receptionate</FormHelperText>
                        {probeListUnreceived.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListUntested.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe nelucrate</FormHelperText>
                        {probeListUntested.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!!probeListReported.length && (
                      <FormGroup>
                        <FormHelperText className={classes.noProbe}>Probe incluse in alte rapoarte</FormHelperText>
                        {probeListReported.map((p) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={p.pno}
                                checked={!!selectedProbes.find((sp) => sp === p.pno)}
                                onChange={handleChangeProbes}
                              />
                            }
                            label={p.pno}
                            key={p.pno}
                          />
                        ))}
                      </FormGroup>
                    )}
                    {!probe.length && (
                      <Box>
                        <FormHelperText error className={classes.noProbe}>
                          Nu exista probe disponibile!
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                )}
                {!!pvp && !!selectedProbes.length && !loading && (
                  <>
                    <DocumentForm
                      data={attributes}
                      error={error}
                      requiredFields={REPORT_MANDATORY_FIELDS}
                      tempAttributes={template.contract.tempAttributes}
                      onChange={handleChangeData}
                    />
                    {/* <Grid item xs={12}>
                      <MultipleSelect
                        label="Identificare metodă/standard de incercare"
                        list={products || []}
                        value={selectedProducts || []}
                        render={(selected: string[]) => {
                          if (Array.isArray(selected)) return selected.join(', ');
                          return '';
                        }}
                        onChange={handleChangeProducts}
                      />
                    </Grid> */}
                  </>
                )}
                {/* {!!probesError && pvp && (
                  <Grid item xs={12}>
                    <Box>
                      <FormHelperText error>{probesError}</FormHelperText>
                    </Box>
                  </Grid>
                )} */}
              </>
            )}
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={saveHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!template || !pvp || !probe.length || !selectedProbes.length}
            loading={loading}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default ProjectReportCreateModal;
