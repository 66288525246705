import PropTypes from 'prop-types';
import React, { FC } from 'react';
import Form from 'src/components/Form';
import { Email as Model } from 'src/types/email';
import * as Yup from 'yup';

import { Button, Link, Typography } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { downloadDocUtil } from 'src/utils/downloadDocument';

interface Props {
  className?: string;
  entity: Model;
  action: (values: any) => Promise<void | string | object>;
  labels: {
    cardTitle: string;
    submitLabel: string;
    messages: {
      success: string;
      fail: string;
    };
  };
  loading: boolean;
  cardHeaderAction?: any;
  source?: string;
}

const EntityEditForm: FC<Props> = ({ entity, action, labels, loading, cardHeaderAction, source }) => {
  const { token } = useAuth();

  const renderAttachment = (id?: string) => {
    if (id)
      if (source) {
        return (
          <Typography color="primary">
            Atasament
            <br />
            <Button
              color="white"
              onClick={() =>
                downloadDocUtil(id, {
                  title: entity.title,
                  docType: 'FILE',
                  token
                })
              }
            >
              {entity.title}
            </Button>
          </Typography>
        );
      } else
        return (
          <Typography color="primary">
            Atasament
            <br />
            <Link component="a" color="textSecondary" underline="always" href={`/download/${id}`} target="_blank">
              {entity.title}.pdf
            </Link>
          </Typography>
        );
    return null;
  };

  return (
    <Form
      fields={[
        {
          label: 'De la',
          name: 'from',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.from,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Catre',
          name: 'to',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.to,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'CC',
          name: 'cc',
          initialValue: entity?.cc,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Subiect',
          name: 'subject',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.subject,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Titlu',
          name: 'title',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.title,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Continut',
          name: 'content',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.content,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        },
        {
          label: 'Subsol',
          name: 'footer',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.footer,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        }
      ]}
      messages={labels.messages}
      action={action}
      submitLabel={labels.submitLabel}
      cardTitle={labels.cardTitle}
      error={!entity}
      cardHeaderAction={cardHeaderAction}
      spacing={3}
      footer={renderAttachment(entity.attachment)}
    />
  );
};

EntityEditForm.propTypes = {
  className: PropTypes.string
};

export default EntityEditForm;
