import { Category } from './category';
import { Client } from './client';
import { ProjectContract } from './contract';
import { Lab } from './lab';
import { Project } from './project';
import { PVP } from './pvp';
import { Site } from './site';

export class Report {
  id?: string;
  no?: number;
  pno?: string;
  created?: Date;
  updated?: Date;
  projectContract?: ProjectContract;
  pvp?: PVP;
  category: Category;
  laboratory: Lab;
  client: Client;
  site: Site;
  project: Project;
  data: { [key: string]: string | number | Array<any> };

  constructor(id?: string) {
    if (id) this.id = id;
    this.laboratory = { id: '' } as Lab;
    this.client = { id: '' } as Client;
    this.site = { id: '' } as Site;
    this.project = { id: '' } as Project;
    delete this.id;
    delete this.created;
    delete this.updated;
    delete this.projectContract;
    delete this.pvp;
    delete this.no;
  }
}
