import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Archive as ArchiveIcon,
  Book,
  Briefcase as BriefcaseIcon,
  Command as CommandIcon,
  Home as HomeIcon,
  Inbox as InboxIcon,
  Send as SendIcon,
  ShoppingBag as ShoppingBagIcon,
  Thermometer as ThermometerIcon,
  Users as UsersIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

/* eslint-disable no-use-before-define */
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles } from '@material-ui/core';

import NavItem from './NavItem';

import type { FC, ReactNode } from 'react';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Flux',
    items: [
      {
        title: 'Proiecte',
        href: '/app/flux/projects',
        icon: InboxIcon
      },
      {
        title: 'Emails',
        href: '/app/flux/emails',
        icon: SendIcon
      },
      {
        title: 'Comenzi',
        href: '/app/flux/orders',
        icon: CommandIcon
      },
      {
        title: 'Registru receptie',
        href: '/app/flux/probes-registry',
        icon: Book
      },
      {
        title: 'Prelucrare probe',
        href: '/app/flux/probes',
        icon: ThermometerIcon
      },
      {
        title: 'Registru presa',
        href: '/app/flux/probes-press',
        icon: Book
      },
      {
        title: 'Registru rapoarte',
        href: '/app/flux/reports-registry',
        icon: Book
      }
      // {
      //   title: 'Registru rapoarte',
      //   href: '/app/flux/reports',
      //   icon: CommandIcon
      // }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Utilizatori',
        href: '/app/management/users',
        icon: UsersIcon
      },
      {
        title: 'Categorii',
        icon: BriefcaseIcon,
        href: '/app/management/categories'
      },
      {
        title: 'Servicii',
        icon: BriefcaseIcon,
        href: '/app/management/products'
      },
      {
        title: 'Laboratoare',
        icon: HomeIcon,
        href: '/app/management/labs'
      },
      {
        title: 'Clienti',
        icon: ShoppingBagIcon,
        href: '/app/management/clients'
      },
      {
        title: 'Santiere',
        icon: ArchiveIcon,
        href: '/app/management/sites'
      },
      {
        title: 'Roluri',
        icon: BriefcaseIcon,
        href: '/app/management/roles'
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  userScopes
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  userScopes?: string;
}) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 230
  },
  desktopDrawer: {
    width: 230,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const userScopes = user?.role?.scope || '';
  const userRole = user?.role?.name || '';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const filteredSections = sections
    .map((section) => {
      let filteredItems = [...section.items];
      if (userRole !== 'Admin') {
        filteredItems = section.items.filter((i) => userScopes.indexOf(i.href.slice(5)) >= 0);
      }
      return { ...section, items: filteredItems };
    })
    .filter((section) => section.items?.length);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                // alt={user.firstName}
                className={classes.avatar}
                src="https://www.clipartmax.com/png/middle/248-2487966_matthew-man-avatar-icon-png.png"
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user.firstName} {user.lastName} {user.email}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {filteredSections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                userScopes: user.role?.scope || ''
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
