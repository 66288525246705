import { reducer as formReducer } from 'redux-form';
import { reducer as notificationReducer } from 'src/slices/notification';

import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  form: formReducer,
  notifications: notificationReducer
});

export default rootReducer;
