import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface AuthClientGuardProps {
  children?: ReactNode;
}

const AuthClientGuard: FC<AuthClientGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/connect" />;
  } else if (isAuthenticated && !user?.hasAccess) {
    return <Redirect to="/no-access" />;
  } else if (!user.isClient) {
    return <Redirect to="/app" />;
  }

  return <>{children}</>;
};

AuthClientGuard.propTypes = {
  children: PropTypes.node
};

export default AuthClientGuard;
