import { uniqBy } from 'lodash';
import { TemplateAttribute } from 'src/types/contract';

export const extractTemplateVariables = (
  template: string,
  regex: RegExp = /{{(.*?)}}/g,
  separator: string = '::'
): TemplateAttribute[] => {
  let match: RegExpExecArray | null;
  let extracted: TemplateAttribute[] = [];
  while ((match = regex.exec(template))) {
    let item: TemplateAttribute = { key: match[1] };
    if (separator) {
      const [key, type] = item.key.split(separator);
      item = { key, type };
    }
    extracted.push(item);
  }
  const uniqueExtracted = uniqBy(extracted, 'key');
  return uniqueExtracted;
};

export const formatLabelText = (text: string, labelReplace = 'data.'): string => {
  if (!text) return '';
  text = text.replace(labelReplace, '');
  return text.charAt(0).toUpperCase() + text.slice(1).replace(/_/g, ' ');
};
