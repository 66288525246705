/* eslint-disable no-fallthrough */

import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import { Theme } from 'src/theme';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { exportHandler: (startDate: string, endDate: string) => {} };
}

const AddClientModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const [interval, setInterval] = useState<{ startDate: string; endDate: string }>({ startDate: null, endDate: null });
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!rest.visible) {
      setInterval({ startDate: null, endDate: null });
      setError(false);
    }
  }, [rest.visible]);

  const dateChangeHandler = (field: string) => (date: moment.Moment) => {
    const val = date?.format('YYYY-MM-DD');
    setInterval({ ...interval, [field]: val });
    setError(false);
  };

  const isValid = (): boolean => {
    if (moment(interval.startDate).isAfter(moment(interval.endDate))) {
      setError(true);
      return false;
    }
    return true;
  };

  const exportAction = async () => {
    if (!isValid()) return;
    extra.exportHandler(interval.startDate, interval.endDate);
    rest.setNotify(new Date().getTime());
    rest.cancel();
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Exporta excel"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                label="Data inceput"
                name="startDate"
                inputVariant="outlined"
                fullWidth
                value={interval.startDate ? new Date(interval.startDate + '') : null}
                format="DD.MM.YYYY"
                onChange={dateChangeHandler('startDate')}
                error={error}
                helperText={error && 'Data inceput trebuie sa fie inainte de data sfarsit'}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                label="Data sfarsit"
                name="endDate"
                inputVariant="outlined"
                fullWidth
                value={interval.endDate ? new Date(interval.endDate + '') : null}
                format="DD.MM.YYYY"
                onChange={dateChangeHandler('endDate')}
                error={error}
                helperText={error && 'Data sfarsit trebuie sa fie dupa data inceput'}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={exportAction}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!interval.startDate || !interval.endDate}
          >
            Exporta
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default AddClientModal;
