import { Client } from './client';
import { UploadedFile } from './file';
import { Lab } from './lab';
import { ProjectProduct } from './product';
import { Site } from './site';

export class Project {
  id?: string;
  no: number;
  pno?: string;
  valability?: string = new Date().toISOString();
  contractDate: string = new Date().toISOString();
  paymentDays?: number = 15;
  created?: Date;
  updated?: Date;
  projectProducts?: ProjectProduct[];
  laboratory?: Lab;
  client?: Client;
  site?: Site;
  files?: UploadedFile[];

  constructor(id?: string) {
    if (id) this.id = id;
  }
}
