import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ResultsList from 'src/components/ResultsList';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import api from 'src/lib/api';
import { ProjectProduct as Model } from 'src/types/product';

import { Switch } from '@material-ui/core';

const BOX_TITLE = 'Lista servicii comanda';

interface Props {
  parentId: string;
  products: Model[];
  setProducts: (products: Model[]) => void;
}

const EntityListView: FC<Props> = ({ parentId, setProducts, products }) => {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState<Model[]>([]);

  const getList = useCallback(async () => {
    try {
      if (isMountedRef.current && parentId) {
        const projectProducts = await api.projectProducts.listForProject(parentId);
        const newList = projectProducts.result.filter((pp) => pp.enabled === true);
        setList(newList);
        setProducts([]);
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, parentId]);

  const handleChangeItem = async (itemId: string, field: string, value: string | boolean | number) => {
    try {
      // TODO: add data to State and control component
      const oldItemIndex = list.findIndex((i) => i.id === itemId);
      const oldItem = list[oldItemIndex];
      if (oldItem) {
        const projectProduct = { ...oldItem, [field]: value } as Model;
        const newList = [
          ...list.slice(0, oldItemIndex),
          {
            ...projectProduct
          },
          ...list.slice(oldItemIndex + 1)
        ];
        setList(newList);
        if (value) {
          products.push(projectProduct);
        } else {
          products = products.filter((p) => p.id !== projectProduct.id);
        }
        setProducts(products);
      } else {
        throw new Error('ITEM_NOT_FOUND');
      }
    } catch (e) {
      enqueueSnackbar(`Problema actualizare serviciu: ${e?.message}`, { variant: 'error' });
    }
  };

  const columns = [
    { id: 'category.name', label: 'Categorie' },
    { id: 'name', label: 'Nume serviciu', style: { width: '50%', minWidth: 230 } },
    { id: 'um', label: 'Unitate de masura' },
    {
      id: 'price',
      label: 'Pret serviciu',
      style: { minWidth: 185 }
    },
    {
      id: 'selected',
      label: 'Selectat',
      render: (value, item) => (
        <Switch value={value} onChange={(e) => handleChangeItem(item.id, 'selected', !!e.target?.checked)} />
      )
    }
  ];

  useEffect(() => {
    getList();
  }, [getList, parentId]);

  return <ResultsList columns={columns} data={list} title={BOX_TITLE} height="100%" />;
};

export default EntityListView;
