import React, { useRef } from 'react';
import { ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

import { Avatar, Box, Button, Hidden, makeStyles, SvgIcon, Typography } from '@material-ui/core';

import type { FC } from 'react';
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

const Workstation: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { user } = useAuth();

  const handleOpen = (): void => {
    history.push('/set-workstation');
  };

  const initials = `${user.laboratory.companyName.split(' ')[0][0]}${user.workstation.name.split(' ')[0][0]}`;

  return (
    <>
      <Box display="flex" alignItems="left">
        <Button color="inherit" ref={ref} onClick={handleOpen}>
          <Avatar className={classes.avatar} src="test">
            {initials}
          </Avatar>
          <Hidden smDown>
            <Typography style={{ fontSize: 13, marginLeft: 10 }}>{user.laboratory.companyName}</Typography>
            <SvgIcon style={{ fontSize: 14, marginLeft: 10, marginRight: 10 }}>
              <ArrowRight />
            </SvgIcon>
            <Typography style={{ fontSize: 13 }}>{user.workstation.name}</Typography>
          </Hidden>
        </Button>
      </Box>
    </>
  );
};

export default Workstation;
