import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface AuthGuardProps {
  children?: ReactNode;
}

const ChangeLabGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/connect" />;
  } else if (isAuthenticated && !user?.hasAccess) {
    return <Redirect to="/no-access" />;
  } else if (user.isClient) {
    return <Redirect to="/client" />;
  } else if (!user.isClient && !user.role?.id) {
    return <Redirect to="/no-access" />;
  }

  return <>{children}</>;
};

ChangeLabGuard.propTypes = {
  children: PropTypes.node
};

export default ChangeLabGuard;
