import PropTypes from 'prop-types';
import React, { FC } from 'react';
import Form from 'src/components/Form';
import { Category } from 'src/types/category';
import { Product as Model } from 'src/types/product';
import * as Yup from 'yup';

interface Props {
  className?: string;
  entity: Model;
  action: (values: any) => Promise<void | string | object>;
  labels: {
    cardTitle: string;
    submitLabel: string;
    messages: {
      success: string;
      fail: string;
    };
  };
  loading: boolean;
  extra: { categories: Category[] };
  cardHeaderAction?: any;
}

const EntityEditForm: FC<Props> = ({ entity, action, labels, loading, extra, cardHeaderAction }) => {
  return (
    <Form
      fields={[
        {
          label: 'Categorie',
          name: 'category',
          validation: Yup.string().required('Categoria este obligatorie'),
          initialValue: entity?.category?.id,
          type: 'option',
          key: 'id',
          value: 'id',
          text: 'name',
          options: extra.categories
        },
        {
          label: 'Nume serviciu',
          name: 'name',
          validation: Yup.string().required('Numele este obligatoriu'),
          initialValue: entity?.name
        },
        {
          label: 'Unitate de masura',
          name: 'um',
          validation: Yup.string().required('U.M. este obligatorie'),
          initialValue: entity?.um
        },
        {
          label: 'Pret de lista',
          name: 'price',
          validation: Yup.string().nullable(),
          initialValue: entity?.price,
          props: {
            type: 'number',
            InputLabelProps: { shrink: true },
            InputProps: { inputProps: { min: 0, step: '0.01' } }
          }
        }
      ]}
      messages={labels.messages}
      action={action}
      submitLabel={labels.submitLabel}
      cardTitle={labels.cardTitle}
      error={!entity}
      cardHeaderAction={cardHeaderAction}
    />
  );
};

EntityEditForm.propTypes = {
  className: PropTypes.string
};

export default EntityEditForm;
