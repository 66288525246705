/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { FC, FocusEvent, useCallback, useState } from 'react';
import Button from 'src/components/Button';
import useAuth from 'src/hooks/useAuth';
import api from 'src/lib/api';
import { ATTACHMENT_TYPE, UploadedFile } from 'src/types/file';
import { Breakpoint } from 'src/types/forms';
import * as Yup from 'yup';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  GridSpacing,
  IconButton,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';

import FilesDropzone from './FilesDropzone';

interface FormProps {
  className?: string;
  messages: {
    success: string;
    fail: string;
  };
  action?: (values: object) => Promise<void | string | object | boolean>;
  fields: {
    name: string;
    label: string;
    initialValue: string | number | boolean | Date | UploadedFile[];
    validation?: object;
    props?: any;
    pos?: {
      lg?: Breakpoint;
      md?: Breakpoint;
      sm?: Breakpoint;
      xs?: Breakpoint;
    };
    type?: string;
    options?: any[];
    useKey?: boolean;
    key?: string;
    text?: string;
    value?: string;
    emptyOption?: boolean;
    disabled?: boolean;
    multipleUpload?: boolean;
    customText?: string;
    filterType?: ATTACHMENT_TYPE;
    noAdd?: boolean;
    noDelete?: boolean;
    noEmail?: boolean;
    customAction?: (value: any) => Promise<void>;
    customLoading?: boolean;
    showButton?: boolean;
    customHandlerBlur?: (e: FocusEvent<HTMLInputElement>) => Promise<void>;
  }[];
  submitLabel: string;
  cardTitle: string;
  cardHeaderAction?: any;
  error?: boolean;
  withAttachments?: boolean;
  spacing?: GridSpacing;
  footer?: any;
  onlyFields?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

type InitialValuesType = {
  submit: string;
  [key: string]: string | number | boolean | Date | UploadedFile[];
};

const useStyles = makeStyles(() => ({
  root: {},
  checkbox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const FILE_API = api.files;

const Form: FC<FormProps> = ({
  className,
  messages,
  action,
  fields,
  submitLabel,
  cardTitle,
  error,
  withAttachments,
  cardHeaderAction,
  spacing,
  footer,
  onlyFields,
  ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const [attachments, setAttachments] = useState<UploadedFile[]>([]);

  const initialValues: InitialValuesType = { submit: '' };
  const validations = {};
  for (const field of fields) {
    if (field.props?.type === 'number' && !field.initialValue) {
      field.initialValue = 0;
    }
    initialValues[field.name] = field?.initialValue ?? '';

    validations[field.name] = field?.validation ?? Yup.string();
    if (!field.pos) field.pos = {};
  }
  const validationSchema = Yup.object().shape(validations);

  const onDrop = useCallback((newAttachments) => {
    setAttachments((prevAttachments) => [...prevAttachments].concat(newAttachments));
  }, []);

  const CardWrap = onlyFields ? React.Fragment : Card;
  const CardContentWrap = onlyFields ? React.Fragment : CardContent;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          delete values.submit;
          if (withAttachments) {
            delete values.attachment;
            const formData = new FormData();
            if (attachments?.length) {
              attachments.forEach((item) => formData.append('attachment', item));
            }
            Object.keys(values).forEach((key) => formData.append(key, values[key] + ''));
            await action(formData);
          } else {
            await action(values);
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          setAttachments([]);
          enqueueSnackbar(messages.success, { variant: 'success' });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          if (err.field) {
            setErrors({ [err.field]: err.message });
          }
          setSubmitting(false);
          if (err.field === 'submit') {
            enqueueSnackbar(`${messages.fail}: ${err.message}`, {
              variant: 'error'
            });
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values, status }) => (
        <form onSubmit={handleSubmit}>
          <CardWrap className={clsx(classes.root, className)} {...rest}>
            {!onlyFields && <CardHeader title={cardTitle} action={cardHeaderAction} />}
            <Divider style={{ marginBottom: onlyFields ? 30 : 0 }} />
            <CardContentWrap>
              <Grid container spacing={spacing || 4}>
                {error && (
                  <Typography variant="h4" color="textPrimary" style={{ padding: 30 }}>
                    Se incarca...
                  </Typography>
                )}
                {!error &&
                  fields.map((field) => {
                    return (
                      <Grid
                        item
                        lg={field.pos.lg ?? 6}
                        md={field.pos.md ?? 6}
                        sm={field.pos.sm ?? 12}
                        xs={field.pos.xs ?? 12}
                        key={field.label}
                      >
                        {!field.type && (
                          <TextField
                            error={Boolean(touched[field.name] && errors[field.name])}
                            fullWidth
                            helperText={touched[field.name] && errors[field.name]}
                            label={field.label}
                            name={field.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values[field.name]}
                            variant="outlined"
                            disabled={!!field.disabled}
                            InputLabelProps={{ shrink: !!values[field.name] }}
                            {...field.props}
                          />
                        )}
                        {field.type === 'checkbox' && (
                          <div className={classes.checkbox}>
                            <FormControlLabel
                              control={
                                // <Checkbox checked={!!values[field.name]} onChange={handleChange} name={field.name} />
                                <Switch checked={!!values[field.name]} onChange={handleChange} name={field.name} />
                              }
                              label={field.label}
                            />
                          </div>
                        )}
                        {field.type === 'option' && (
                          <TextField
                            fullWidth
                            label={field.label}
                            name={field.name}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values[field.name]}
                            variant="outlined"
                            disabled={!!field.disabled}
                            InputLabelProps={{ shrink: !!values[field.name] }}
                            error={Boolean(touched[field.name] && errors[field.name])}
                            helperText={touched[field.name] && errors[field.name]}
                          >
                            <option key="" value="" disabled={!field.emptyOption} />
                            {field.options.map((value) => (
                              <option
                                key={field.key ? value[field.key] : value}
                                value={field.value ? value[field.value] : value}
                              >
                                {field.text ? value[field.text] : value}
                              </option>
                            ))}
                          </TextField>
                        )}
                        {field.type === 'date' && (
                          <KeyboardDatePicker
                            fullWidth
                            label={field.label}
                            name={field.name}
                            inputVariant="outlined"
                            value={new Date(values[field.name] + '')}
                            format="DD-MM-YYYY"
                            onChange={(date) => {
                              const formatDate = date?.toISOString();
                              setFieldValue(field.name, formatDate);
                            }}
                          />
                        )}
                        {field.type === 'upload' && (
                          <FilesDropzone
                            multiple={field.multipleUpload}
                            customText={field.customText}
                            onDrop={onDrop}
                            noAdd={field.noAdd}
                            noEmail={field.noEmail}
                            onDelete={
                              !field.noDelete &&
                              (async (index: number, fileId: string) => {
                                let arrValues = values[field.name] as UploadedFile[];
                                if (field.filterType)
                                  arrValues = arrValues.filter((v) => v.attachmentType === field.filterType);
                                const i = arrValues.findIndex((f) => f.id === fileId);
                                if (fileId) {
                                  try {
                                    await FILE_API.delete(fileId);
                                    const updatedFiles = [...arrValues];

                                    updatedFiles.splice(i, 1);

                                    setFieldValue(field.name, updatedFiles);
                                  } catch (e) {
                                    console.error(e);
                                  }
                                } else {
                                  const attachmentIndex = index - arrValues?.length;
                                  if (attachmentIndex > -1) {
                                    const updatedAttachments = [...attachments];
                                    updatedAttachments.splice(attachmentIndex, 1);
                                    setAttachments(updatedAttachments);
                                  }
                                }
                              })
                            }
                            onEmailSent={field.customAction}
                            values={values[field.name] as UploadedFile[]}
                            attachments={attachments}
                            filterType={field.filterType}
                            token={token}
                          />
                        )}
                        {field.type === 'fieldWithButton' && (
                          <TextField
                            error={Boolean(touched[field.name] && errors[field.name])}
                            fullWidth
                            helperText={touched[field.name] && errors[field.name]}
                            label={field.label}
                            name={field.name}
                            onBlur={field.customHandlerBlur || handleBlur}
                            onChange={handleChange}
                            value={values[field.name]}
                            variant="outlined"
                            disabled={!!field.disabled}
                            InputLabelProps={{ shrink: !!values[field.name] }}
                            InputProps={{
                              endAdornment:
                                field.showButton && values[field.name]?.toString()?.length > 3 ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      disabled={field.customLoading}
                                      onMouseDown={(e) => e.preventDefault()}
                                      onClick={() => field.customAction(values[field.name])}
                                    >
                                      {field.customLoading ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <CloudDownload fontSize="small" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ) : null
                            }}
                            {...field.props}
                          />
                        )}
                      </Grid>
                    );
                  })}
                {footer && (
                  <Grid item key="footer">
                    {footer}
                  </Grid>
                )}
              </Grid>
            </CardContentWrap>
            {!error && <Divider style={{ marginTop: onlyFields ? 30 : 0 }} />}
            {!error && !!action && (
              <Box p={2} display="flex" justifyContent="flex-end">
                {errors.submit && (
                  <FormHelperText style={{ flexGrow: 1 }} error>
                    {errors.submit}
                  </FormHelperText>
                )}
                <Button
                  loading={isSubmitting || rest.loading}
                  type="submit"
                  disabled={rest.disabled}
                  label={submitLabel}
                  success={(status?.success && isEmpty(touched)) ?? false}
                />
              </Box>
            )}
          </CardWrap>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  className: PropTypes.string,
  action: PropTypes.func,
  submitLabel: PropTypes.string.isRequired
};

export default Form;
